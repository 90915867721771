@use "../abstracts/variables" as *;

.navbar__container {
  background-color: var(--color-content-background);
  border-bottom: 1px solid var(--color-border);

  .navbar {
    max-width: 900px;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__brand {
      color: var(--color-primary-text);
      font-size: 22px;
      font-weight: 600;
    }

    &__items {
      list-style-type: none;
      display: flex;
      align-items: center;

      .navbar__item {
        cursor: pointer;
        flex-shrink: 0;
        position: relative;

        .navbar__icon {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 15px 10px;

          svg {
            width: 22px;
            height: 22px;
          }
        }

        .profile__toggler {
          padding: 10px;
          flex-shrink: 0;
        }

        .profile__avatar {
          width: 35px;
          height: 35px;
          flex-shrink: 0;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--color-primary);
          color: white;
          overflow: hidden;

          .profile__img {
            object-fit: cover;
          }

          .upload__avatar {
            opacity: 0.5;
            background-color: transparent;

            input {
              background: transparent;
            }
          }
        }

        .dropdown {
          cursor: auto;
          list-style-type: none;
          position: absolute;
          top: 120%;
          left: 110%;
          transform: translateX(-100%);
          background-color: var(--color-content-background);
          border-radius: 10px;
          z-index: 15;
          border: 1px solid var(--color-border);

          &.theme__list {
            width: 170px;

            display: flex;
            flex-direction: column;
            overflow: hidden;
            color: var(--color-text-2);

            .theme__item {
              display: flex;
              align-items: center;
              padding: 10px 12px;
              font-size: 15px;
              gap: 12px;
              font-weight: 500;

              .theme__icon {
                display: block;
                width: 19px;
                height: 19px;

                svg {
                  width: 100%;
                  height: 100%;
                }
              }

              .theme__title {
                text-transform: capitalize;
              }

              &.active {
                color: var(--color-primary-text) !important;
              }

              &:hover {
                color: var(--color-text-1);
                background-color: var(--color-content-background-hover);
              }
            }
          }

          &.profile {
            display: flex;
            align-items: center;
            gap: 20px;
            padding: 20px;
            width: fit-content;

            .profile__avatar {
              width: 90px;
              height: 90px;
              font-size: 30px;
              font-weight: 500;
              position: relative;

              input[type="file"] {
                display: none;
              }
              .profile__img {
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                z-index: 4;
              }

              .camera__icon {
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 5;
                background-color: rgba(0, 0, 0, 0.4);
                cursor: pointer;
                opacity: 0;
                transition: 0.2s;
                color: white;

                svg {
                  width: 40px;
                  height: 40px;
                }
              }
              &:hover {
                .camera__icon {
                  opacity: 1;
                }
              }
            }

            .profile__content {
              .profile__email {
                margin-bottom: 10px;
              }

              .profile__link {
                color: var(--color-link);
                font-size: 15px;
                display: block;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
}

.dropdown-enter {
  opacity: 0;
  margin-top: -10px;
}

.dropdown-enter-active {
  opacity: 1;
  margin-top: 0;
  transition: 0.1s;
}

.dropdown-exit {
  opacity: 1;
}

.dropdown-exit-active {
  opacity: 0;
  margin-top: -10px;
  transition: 0.1s;
}

@media (min-width: 1170px) {
  .dropdown {
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}
