.signup__container {
  max-width: 450px;
  margin: 50px auto;
  padding: 0 15px;

  .signup {
    background-color: var(--color-content-background);
    border-radius: 10px;
    padding: 20px;
    border: 1px solid var(--color-border);
    position: relative;
    overflow: hidden;

    .form__title {
      text-align: center;
      margin-bottom: 15px;
    }

    .form__message {
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      text-align: center;
      font-size: 15px;

      &.error {
        color: var(--color-text-1);
        background-color: var(--color-danger-background);
      }

      &.success {
        color: var(--color-success-text-1);
        background-color: var(--color-success-background);
      }
    }

    .form {
      margin-top: 25px;
    }

    .form__submit {
      width: 100%;
      background-color: var(--color-primary);
      color: white;
      padding: 10px 16px;
      margin-top: 15px;
      border-radius: 5px;
      font-size: 15px;
      font-weight: 500;

      &:first-of-type {
        margin-top: 30px;
      }

      &.google {
        background-color: var(--color-danger);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 8px 16px;

        svg {
          font-size: 25px;
        }
      }
    }

    .form__footer {
      text-align: center;
      font-size: 15px;
      margin-top: 10px;

      a {
        color: var(--color-primary-text);

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
