@use "../abstracts/variables" as *;
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500&display=swap");

html {
  box-sizing: border-box;
  min-width: 100vw;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  transition: background-color 0.1s, border 0.1s;
}

body {
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: var(--color-text-1);
  background-color: var(--color-background);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

input {
  font-family: inherit;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

.clearfix {
  overflow: auto;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

img {
  width: 100%;
  height: 100%;
}
