.item {
  background-color: var(--color-content-background);
  display: flex;
  border: 1px solid var(--color-border);
  align-items: center;
  justify-content: space-between;
  padding: 7px 15px;
  gap: 10px;
  position: relative;
  margin-bottom: 7px;

  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .drag__icon {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    padding: 8px;
    bottom: 0;
    color: var(--color-text-3);
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;

    svg {
      font-size: 25px;
      height: 100%;
    }
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;

    .item__completed {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid var(--color-primary-text);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.1s;

      .item__checkbox {
        width: 100%;
        height: 100%;
        transform: scale(1.3);
        position: absolute;
        inset: 0;
        opacity: 0;
        cursor: pointer;
        display: none;
      }

      .checked__icon {
        opacity: 0;
        color: var(--color-primary-text);
        transition: 0.1s;
        font-size: 20px;
      }

      &:hover {
        .checked__icon {
          opacity: 1;
        }
      }
    }

    .item__input {
      width: 100%;
      height: 30px;
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 15px;
      color: var(--color-text-1);
      transition: 0.1s;
      transition: 0.1s;
    }
  }

  .delete__icon {
    box-sizing: content-box;
    color: var(--color-text-2);
    font-size: 22px;
    cursor: pointer;
    transition: 0.1s;
    padding: 10px 0;

    &:hover {
      color: var(--color-danger-text);
    }
  }

  &.completed {
    .item__completed {
      background-color: var(--color-primary-text);
      color: white;

      .checked__icon {
        color: var(--color-content-background);
        opacity: 1;
      }
    }
    .item__input {
      color: var(--color-text-2);
      text-decoration: line-through;
    }
  }
  &:hover {
    background-color: var(--color-content-background-hover);

    .drag__icon {
      opacity: 0.7;
    }
  }
}

.todo-enter {
  opacity: 0;
  transform: translateY(-20px);
}
.todo-enter-active {
  opacity: 1;
  transform: none;
  transition: all 0.2s ease-in;
}
.todo-exit {
  opacity: 1;
}
.todo-exit-active {
  opacity: 0;
  transition: all 0.2s ease-in;
  transform: translateY(20px);
}

.touchscreen {
  .item:hover {
    background-color: var(--color-content-background);
  }
  .item:not(.completed) .item__completed:hover {
    .checked__icon {
      opacity: 0;
    }
  }
  .drag__icon {
    display: none;
  }
}
