@use "../abstracts/variables" as *;

:root {
  --color-primary: #{$blue-600};
  --color-primary-text: #{$blue-700};
  --color-danger: #{$red-600};
  --color-danger-background: #{$red-100};
  --color-danger-text: #{$red-500};
  --color-success: #{$green-600};
  --color-success-background: #{$green-100};
  --color-success-text: #{$green-500};
  --color-success-text: #{$green-600};

  --color-background: #f3f3f3;
  --color-content-background: #fff;
  --color-content-background-hover: #f5f5f5;
  --color-border: #e0e3e6;
  --color-text-1: #{$gray-700};
  --color-text-2: #{$gray-600};
  --color-text-3: #{$gray-500};
  --color-brand: #293241;
  --color-link: #{$blue-800};
}
