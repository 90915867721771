@use "../abstracts/variables" as *;

.dark {
  --color-primary: #{$blue-700};
  --color-primary-text: #{$blue-400};
  --color-danger: #{$red-700};
  --color-danger-text: #{$red-300};
  --color-danger-background: #{$red-700};
  --color-success: #{$green-800};
  --color-success-text: #{$green-300};
  --color-success-background: #{$green-900};

  --color-background: #0a1929;
  --color-content-background: #0c1f33;
  --color-border: #183049;
  --color-content-background-hover: #14273d;
  --color-text-1: #{$gray-300};
  --color-text-2: #{$gray-400};
  --color-text-3: #{$gray-500};
  --color-brand: #fff;
  --color-link: #{$gray-300};

  &.touchscreen {
    --color-border: #263b53;
  }
}
